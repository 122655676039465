/* Base styles for the file details area */
.file-details {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}

/* Animation for uploading status */
@keyframes pulse {
    0% { background-color: #fff; }
    50% { background-color: #f0f0f0; }
    100% { background-color: #fff; }
}

.file-uploading {
    color: #ff8c00;
    animation: pulse 2s infinite;
}

/* Animation for uploaded status */
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Base styles for the upload status */
.upload-status {
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    border:2px solid black;
}

/* Styles when upload has not started */
.upload-status.idle {
    border: 2px solid orange;
}

/* Styles when upload is in progress or completed */
.upload-status.active {
    border: 2px solid darkgreen;
    background-color: #e0f2f1; /* Very light green */
}

/* Style for the file input */
input[type="file"] {
    font-size: 16px;
    padding: 8px;
    margin: 10px 0;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

/* Style for the upload button */
/* button {
    background-color: #4CAF50; 
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
} */

/* button:hover {
    background-color: #45a049; 
} */

.file-upload-container {
    margin: 10px;
    position: relative;
    display: inline-block;
}

#customButton {
    background-color: #4CAF50; /* Green background */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    outline: none;
}

#customButton:hover {
    background-color: #45a049; /* Darker shade for hover */
}

#fileName {
    margin-left: 10px;
    font-size: 16px;
    vertical-align: middle;
}


