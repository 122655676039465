// change the theme
$theme-colors: (
  "primary": #A50034,
  "danger": #ff4136
 
);


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

$font-size-base: 1rem; // or whatever size you prefer

.list-group-item.active {
  --bs-list-group-active-bg: #A50034; // Specific scope
  background-color: var(--bs-list-group-active-bg);
}


.table {
  font-size: 1rem;
}


thead th {
  font-weight:700;
}
th {
  font-weight:400;
}

