:root {
  --hs-red:#A50034;
}


body {
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5rem!important;
}

p {font-weight:normal;
font-size: 1.5rem;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 2.5rem!important;
  font-weight:600;
  color:rgb(0, 0, 0)!important;
}

h2 {
  font-size: 2.0rem!important;
  font-weight:600;
}

h3 {
  font-size: 2.0rem!important;
  font-weight:600;
}

.Icon_svg { 
  /* color:rgb(0, 0, 0);  */
  padding-bottom:5px;
  padding-right:5px;
  border:0px solid blue}

.url_val {
  padding:10px;
  color:white;
  font-size: 1rem;
  background-color: var(--hs-red);
}

#filename {
  padding:0px;
  margin:0px;
  max-width:20%;
  border:0px;
  background-color: #888888;
  color: white;
}

a {color:orange!important;}

.fixed-info {
  top:0px;
  color:white;
  left:0px;
  height:40px;
  padding:10px;
  min-width:100%;
  position:fixed;
  border-bottom:2px solid black;
  background-color: grey;
  z-index:99;
}
.accordion{
  --bs-accordion-btn-bg: #e6e6e6!important; 
  border:1px solid rgba(124, 124, 124, 0.808);
  margin-bottom:20px;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,0.15);
}

.accordion-filled.accordion-flush {
  --bs-accordion-btn-bg: #e6f8e6!important; 
  border:1px solid rgba(0, 236, 0, 0.377);
  border-left: 4px solid #0d8039;
  margin-bottom:20px;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,0.15);
}


.lehrver .accordion-button:not(.collapsed){
  --bs-accordion-btn-bg: #00e9a3!important; 
  border:1px solid rgba(124, 124, 124, 0.808);
  color:black!important;

  background-color:#B6CFD0!important;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,0.15);

  border-left:0px!important;
}

.info .accordion-button {
  background-color:#eae48b5d!important;
  color:black;
 
  font-size: 1.2rem;
}

.info .accordion-button:not(.collapsed){
  border-top:2px solid #fff200!important;
  color:black!important;
  background-color:#f3efbc!important;
  box-shadow: 0px 5rgb(58, 215, 89)1px rgba(0,0,0,0.15);
  font-size: 1.2rem!important;
}

.info .accordion-body {
  border-left:2px solid #e0d72d!important;
  background-color:#ebe9c5!important;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1.2rem;
}

.info .accordion-button:not(.collapsed){
  border-left:10px solid #f3e712!important;
  background-color:#f9f498!important;
  color:black!important;
  
}

.info .accordion-body ul {
  font-size: 1.3rem;
  line-height: 1.7rem;
}



.accsub .accordion-button {
  background-color:#ffffff;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)!important;
  color:black;
  font-size: 1.2rem;
}

.accsub .accordion-button:not(.collapsed){
  border-top:2px solid #676767!important;
  color:black!important;
  background-color:#ffffff;
  /* box-shadow: 0px 5rgb(58, 215, 89)1px rgba(0,0,0,0.15); */
  font-size: 1.2rem!important;
}

.accsub .accordion-body {
  border-left:2px solid #9c9c9c!important;
  background-color:#ffffff;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1.2rem;
}

.accsub .accordion-button:not(.collapsed){
  border-left:10px solid #c1c1c1!important;
  background-color:#ffffff!important;
  color:black!important;
  
}

.accsub .accordion-body ul {
  font-size: 1.3rem;
  line-height: 1.7rem;
}


/* Hides the arrow icon when accordion has no content */
.accordion-no-content .accordion-button::after {
  display: none;
}

/* Prevents the accordion from being collapsible when no content is present */
.accordion-no-content .accordion-button {
  pointer-events: none;
}


.cat-title h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--hs-red)!important;
  padding-bottom:10px;
  border-bottom:6px solid var(--hs-red);
  margin-top: 15px;
  margin-bottom: 20px;
}

.container{
  width:100%;
  display: flex;
  align-items: left;
  justify-content: left;
}

.checkbox-container {
  width:100%;
  border-bottom:1px solid black;
  margin-top:10px;
}
  
input[type="checkbox"]{
  display:none;
}

input[type="checkbox"]:hover + label:before{
  opacity: .5;
}

.checkbox{
  display:inline-block;
  position:relative;
  padding-left:40px;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
  color: #111;
  font-weight: 600;
}

.checkbox p{
  font-size: 1.3rem;
  line-height: 1.7rem;
}

.checkbox:before{
  z-index:15;
  content: '';
  position:absolute;
  left:0;
  top: 10px;
  transition:all 0.3s ease;
  cursor:pointer;
  width:20px;
  border-width: 4px;
  border-style: solid;
  border-color: #444;
  height:20px;
}
input[type="checkbox"]:checked + label{
  color:#000;
}

input[type="checkbox"]:checked +  label:before{
  border-color: transparent;
  border-left-color: #2ecc71;
  border-bottom-color: #2ecc71;
  transform:rotate(-50deg);
  width:22px;
  height:12px;
  top: 3px;
  
}

.accordion-button:not(.collapsed){
  border-left:10px solid #B6CFD0!important;
  background-color:var(--hs-red)!important;
  color:#B6CFD0!important;
}

.acc_upload .accordion-button:not(.collapsed) {
  border-left:10px solid #B6CFD0!important;
  background-color:#e9a135!important;
  color: black!important;
  /* color:#B6CFD0!important; */
}


.accordion-body {
  border-left:2px solid rgb(136, 136, 136);
}

.import-acc .accordion-button {
  background-color: rgb(207, 76, 76);
  color:white;
}

.import-acc .accordion-button:not(.collapsed){
  background-color: rgb(153, 38, 38);
  color:white;
}

.accordion-button:not(.collapsed){
  font-weight: 600;
  border-left:10px solid black;
  background-color: rgb(153, 38, 38);
  color:white;
}



.custom-header:not(.collapsed)::after{
  background-color:rgb(150, 14, 14)!important;
  padding:0px;
  color:black;

  border:4px solid rgb(134, 12, 12);
}

.custom-header:not(.collapsed) {
  background-color: red;
  color: #0280ff!important;
}
.custom-header:focus{
  background-color:rgb(150, 14, 14)!important;
  padding:0px;
  color:black;

  border:4px solid rgb(134, 12, 12)!important;
}


.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  top: 15px;
  left: 15px;
  user-select: none;
  pointer-events: none;
}

.editor-paragraph {
  margin: 0 0 15px 0;
  position: relative;
}



/* index.css */


/* File Selection */
.btn_fileselect_inf {
  float:left;
  padding:10px;
  padding-left:30px;
  padding-right:30px;

  border: 2px solid rgb(145, 145, 145);
  border-top: 0px solid transparent;  /* Sets no border on the top specifically */
  border-left: 0px solid transparent;  /* Sets no border on the top specifically */
  
  height:100%;
}

.btn_fileselect_info_first {
  background-color: #c5c5c5!important;
  height:100%;
}

.btn_fileselect_select {
  float:right;
  background-color:  var(--hs-red);
  font-weight:400!important;
  min-height: 100% !important;
  margin:0 auto;
  color:white;
}

.btn_fileselect_select a {
  color:white;
}

.btn_fileselect_select_pdf {
  float:right;
  padding:10px!important;
  height:100%!important;
  background-color:  var(--hs-red);
  font-weight:400!important;
  min-height: 100% !important;
  margin:0 auto;
  color:white!important;
}

.btn_fileselect_select_pdf a {
  color:white;
}


/* Apply styles to all FormControl components */
.form-text, .form-selec {
  /* Add your global styles here */
  border: 1px solid #9e9e9e;
  background-color: #d3d3d37a;
  color:black!important;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  margin-top:0px;
  margin-bottom:0px;
}

.form-selec option  {
  background-color: rgb(255, 255, 255);
  border-bottom:1px solid black;
  color: #000000;
}

.form-selec option:hover {
  background-color: rgb(218, 122, 122) !important;
}


.form-text-selected, .form-selec-selected {
  background-color: rgba(75, 133, 78, 0.127);
  border: 2px solid #008d10;
  color:#008d109a!important;
  border-left:4px solid #086213;
}

.img-button {
  background-color:var(--hs-red)!important;
  float:right;
  color:white;
  /* height:100%; */
  padding:10px!important;
}

/* Ensure the custom styles are applied to modal-dialog */
.abgab-modal.modal-dialog {
  max-width: 90% !important; /* Override Bootstrap's default */
  max-height: 90vh; /* Adjust height */
}

.abgab-modal .modal-content {
  background-color: blue; /* Test background color */
  padding: 20px; /* Optional: Add some spacing */
}


.img-button a {
  color:white;
}
.form-text-info {
  width:100%;
  background-color: #c7c7c446!important;
  border: 0px solid #f3e712;
  border-left:4px solid #f3e712;
  padding:10px;
  color:grey;

  user-select: none;  /* Ensures text cannot be selected */
  cursor: none;    /* Standard cursor to indicate it is not interactive */
  pointer-events:none;
}

/* Container for noPDF Preview */
.form_file_pdf_noPDF {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Example fixed height */
  text-align: center;
  font-weight: 400;
  background-color: #e1e1e1; /* Light background for visibility */
  border: 1px solid #ccc; /* Adds a border for visibility */
}


.formgroup {
  min-width: 100%;
  /* background-color: #f8f8f8; */
  /* margin-bottom:0px; */
  border-bottom:1px solid black,
  
}

.formgroup label {
 color:rgb(0, 0, 0);
 font-weight: 500;
 margin:0px;
 padding:5px
}

.formgroup-selected label {
  color:rgba(0, 130, 46, 0.637);
  font-weight: 500;
  margin:0px;
  padding:5px
 }

.formgroup label p {
  color:rgb(0, 0, 0);
  font-weight: 400;
 }

.formgroup .helper::before {
  content: "X";
}

.formgroup .helper {
  color:rgb(0, 0, 0);
  font-weight: 400;
  font-style: italic;
 }

.nrblock {
  text-align:right!important;

}

.form-snr {
  padding:2px;
  border: 1px solid #9e9e9e;
  background-color: #f1f1f17a;
  text-align: center;
  border:0px;
}

.form-file-img-prev {
  background-color: #c0c0c07a;
  height: 100%;
  padding: 20px;
  color: rgb(80, 80, 80);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
}

/* Form Checkb */
/* Stylish Checkbox Styles */
.form-chkb {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 1.5rem!important;
}

.form-chkb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.form-chkb .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-chkb:hover .checkmark {
  background-color: #ddd;
}

.form-chkb input:checked ~ .checkmark {
  background-color: #2196F3!important;
  border: 10px solid #2196F3!important;
}

.form-chkb .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.form-chkb input:checked ~ .checkmark:after {
  display: block;
}

.form-chkb .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Buttons */


.btn-add {   
  background-color:  var(--hs-red)!important;
  border: 2px solid rgb(153, 38, 38)!important;
  font-weight:600!important;
  font-size:1.5rem!important;}

.btn-remove {
background-color: #B6CFD0!important;
color: #000000!important;
border:1px solid  #7e8b8b!important;
font-weight:600!important;
font-size:1.5rem!important;}

.btn-move {
  border:1px solid grey!important;
}

.btn-reset {
  background-color: #f3f3f3!important;
  color: #000000!important;
  border:1px solid  #7e8b8b!important;}

.btn-reset:hover {
  background-color: #e6e6e6!important;
  color: #000000!important;
  border:1px solid  #7e8b8b!important;}

.custom-button {
  background-color:  var(--hs-red)!important;
  border: 2px solid rgb(153, 38, 38)!important;
  color: white!important;
  margin-right:10px;
}

.custom-button:hover {
  color:  rgb(153, 38, 38)!important;
  border: 2px solid rgb(153, 38, 38)!important;
  background-color: white!important;

}

.custom-button-import {
  background-color:  #B6CFD0!important;
  border: 2px solid rgb(0, 0, 0)!important;
  color: rgb(0, 0, 0)!important;
  margin-right:10px;
}

.custom-button-blue {
  background-color: #B6CFD0!important;
  border: 2px solid rgb(0, 0, 0)!important;
  color: rgb(0, 0, 0)!important;
  margin-right:10px;
}

.custom-button-blue:hover {
  background-color: #eff7f7!important;
  border: 2px solid #B6CFD0!important;
  color: #293e3e!important;
  margin-right:10px;
}

.custom-button-import:hover {
  color:  rgb(153, 38, 38)!important;
  border: 2px solid rgb(153, 38, 38)!important;
  background-color: white!important;

}

.custom-tab .nav-item.show .nav-link, .nav-tabs  {
  color:  rgb(153, 38, 38)!important;
  background-color: #fff;
 font-size: 1.5rem;
  border-bottom: 1px solid rgb(153, 38, 38) !important;
  font-weight:400;
}

.nav-tabs .nav-link {
  color: black;
}



.row {font-size:1.5rem;}
.col {font-size: inherit;}

.rowExh {
  font-size: 1.5rem!important;
}
.tab-content {
  padding-top:20px;
  font-size: 1.5rem;
}

.nav-link.active {
  color:  rgb(153, 38, 38)!important;
  background-color: #fff;
  border-left: 2px solid rgb(153, 38, 38) !important;
  border-top: 2px solid rgb(153, 38, 38) !important;
  border-right: 2px solid rgb(153, 38, 38) !important;
  font-weight:600;
}



.nav-tabs {
  border-bottom: 10px solid black;
}

.nav-tabs .nav-link:hover{
 color:rgb(153, 38, 38)!important;
}



.quillEditor .ql-editor {
  min-height: 300px; 
  /* font-size: 1rem!important; */
  line-height: 1.9rem;
  background-color: #eeeeee;
  color:black!important;
}

.quillcount {
  font-size:1.0rem;
  margin-top:0px;
  background-color: #eeeeee;
  border-top:1px solid rgb(171, 171, 171);
  font-weight: 400;
  padding:10px;
  border-left:5px solid green;
}

.quillEditor li {
  font-size:1.2rem;
}

.quillcount.limit-reached {

  color:var(--hs-red);
  border-left:5px solid red;
  font-weight:500;
}

.ql-toolbar {
  border-top:1px solid black;
  border-bottom:1px solid black;
  background-color: #e2e2e2a7;

}

.hidden-checkbox {
  display: none;
}

.custom-button-checkbox {
  cursor: pointer;
  /* Any additional styling */
}

.btn-descr-enc {
  width:100%;
  position: relative;
  /* border:2px solid blue; */
  margin-top:5px;
  margin-bottom:5px;
  word-wrap: break-word;
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

::selection {
 background-color: var(--hs-red);
 color:white;
}

.btn-checkb {
  position: relative;
  min-width: 100%;
  min-width:100%;
  display: block; /* Changed from inline-block to block */
  border-left:4px solid var(--hs-red);
  background-color: #d1d1d1; /* Green */
  color: rgb(0, 0, 0);
  padding: 6px 15px;
  text-align: left;
  text-decoration: none;
  font-size: 1.2rem ;
  font-weight: 400;
  transition-duration: 0.1s;
  cursor: pointer;  
  /* border:2px solid green; */
}

.btn-checkb:hover, .btn-checkb-checkbox:hover {
  background-color: #9e9e9e!important;
  color: rgb(0, 0, 0);
  /* border-left:5px solid rgb(45, 45, 45)!important; */
  box-sizing: border-box;
  color: white!important;
}

.btn-checkb-checkbox {
  width:100%;
}

.btn-checkb-checkbox .hidden-checkbox:checked + .btn-checkb {
  background-color: #c8e4ca;
  color: rgb(0, 0, 0);
  border-left:10px solid green;
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.btn-dscr-eng-enab {
  position: relative;
  background-color: red;
}




.btn-dscr-eng-enab {
  position: absolute;
  background-color: red;
}


.btn-enc {
  position: relative;
  float:none;

}

.btn-enab {background-color: red;}

.btn-checkbox-label {
  background-color: #0280ff;
  padding:0px;
}


.btn-checkbox .hidden-checkbox:checked + .btn-checkbox {
  background-color: #c8e4ca;
  color: rgb(0, 0, 0);
  padding:10px;
  border-left:10px solid green;
}

.btn-enab {
  background-color: rgb(255, 255, 255);
}




.form-label {
  margin-bottom:-20px;
  padding-bottom: 0px;
  font-size:1.2rem;
}


.engCont {
  margin-top:30px;
  border-top:2px solid grey;
  padding-top:20px;
}

.resolution-display {
  padding: 10px;
  margin: 10px 0;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-align: left;
  font-size: 1.5rem;
}

.dpi-calculator {
  font-size: 1.5rem!important;
}


/* Warning Boxes */
.warn-no-crop {
  padding:20px;
  margin-top:20px;
  background-color: #ffdada;
}

.low-resolution {
  background-color: #ffdada; /* Light red background for low resolution */
  color: #d8000c; /* Red text color */
  font-size: 1.5rem;
}

.warning, .warning p {
  color: #d8000c; /* Red text color for the warning */
  font-weight: normal;
  font-size: 1.5rem;
}




/* StudWork */
.studw-footer {
  padding:20px;
  margin-top:50px;
  background-color: #f8f8f8;
  border-top:5px solid var(--hs-red);

}

.studw-footer p {
  font-size: 1.0rem!important;
}

/* Help Button (Component) */
.btn-help {
  background-color: rgb(246, 234, 216);

  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  border-radius: 15px;
  color: black; /* Set default color */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-help:hover {
  background-color: rgb(105, 105, 105);
  color: white; /* Set text color on hover */
}


.btn-help button span {
  color: inherit; /* Ensure text color changes on hover */
  font-size: .21rem;
}

.btn-help button svg {
  margin-right: 20px;
  max-height: 40px;
  max-width: 40px;
  fill: currentColor; /* Use currentColor to inherit the text color */
  transition: fill 0.3s ease;
}

/* Ensure the icon changes color on hover */
.btn-help:hover button svg {
  fill: white; /* Directly set the fill color to white on hover */
}

.btn-help:hover button span {
  color: white; /* Ensure text turns white on hover */
}


.form-file-img-feedback {

}

.positive-feedback {
  background-color:#00e9a3
}


