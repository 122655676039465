.template-preview-wrapper {
    background-color: aliceblue;
    
    border: 1px solid black;
  
}

.template-preview-header {
    margin-bottom: 20px;
    padding:20px;
    border-bottom:1px solid black;
    background-color: rgb(224, 232, 234);
}

.template-preview-content {
    padding:30px;
    margin-bottom: 20px;
}